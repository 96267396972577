import { defineStore } from "pinia";
import { COLORS } from "~/assets/constants";

export type Breadcumb = {
  key: string;
  value: string;
  active?: boolean;
};

export type ColorVariant = `${"bg" | "symbol"}-${keyof typeof COLORS}`;

const DEFAULT_SYMBOL_COLOR = "purpleLight";
const DEFAULT_BG_COLOR = "black";
const DEFAULT_TEXT_COLOR = "white";

export const useHeaderStore = defineStore("header", {
  state: () => ({
    breadcumbsMap: {} as Record<string, Breadcumb[]>,
    symbolColor: COLORS[DEFAULT_SYMBOL_COLOR],
    bgColor: COLORS[DEFAULT_BG_COLOR],
    textColor: COLORS[DEFAULT_TEXT_COLOR],
  }),
  actions: {
    setBreadcumbs(breadcumbs: Breadcumb[]) {
      const route = useRoute();

      this.breadcumbsMap = {
        ...this.breadcumbsMap,
        [route.path]: breadcumbs,
      };
    },
    setSymbolColor(color: keyof typeof COLORS) {
      // color should be string like 'redMedium' 'black' (no the hex)
      this.symbolColor = COLORS[color];
    },
    setBackgroundColor(color: keyof typeof COLORS) {
      this.bgColor = COLORS[color];
    },
    setTextColor(color: keyof typeof COLORS) {
      this.textColor = COLORS[color];
    },

    setColorVariant(variant: ColorVariant | undefined) {
      if (!variant) {
        this.setSymbolColor(DEFAULT_SYMBOL_COLOR);
        this.setBackgroundColor(DEFAULT_BG_COLOR);
        this.setTextColor(DEFAULT_TEXT_COLOR);

        return;
      }

      const [type, color] = variant.split("-") as [
        "bg" | "symbol",
        keyof typeof COLORS
      ];

      const colorNames = Object.keys(COLORS) as (keyof typeof COLORS)[];

      switch (type) {
        case "symbol":
          this.setSymbolColor(color);
          this.setBackgroundColor(DEFAULT_BG_COLOR);
          this.setTextColor(DEFAULT_TEXT_COLOR);
          break;
        case "bg":
          {
            this.setBackgroundColor(color);

            const symbolColorName = colorNames[colorNames.indexOf(color) - 1];

            this.setSymbolColor(symbolColorName);

            if (
              ["white", "redLight", "orangeLight", "yellowLight"].includes(
                color
              )
            ) {
              this.setTextColor("black");
            } else {
              this.setTextColor("white");
            }
          }
          break;
      }
    },
  },
});
